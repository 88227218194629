import {CUSTOM_ELEMENTS_SCHEMA, NgModule} from "@angular/core";
import {CommonModule} from "@angular/common";

import {MenuComponent} from "./menu/menu.component";
import {MatBadgeModule} from "@angular/material/badge";
import {MatButtonModule} from "@angular/material/button";
import {MatCardModule} from "@angular/material/card";
import {MatLineModule, MatRippleModule} from "@angular/material/core";
import {MatIconModule} from "@angular/material/icon";
import {MatListModule} from "@angular/material/list";
import {MatMenuModule} from "@angular/material/menu";
import {MatSidenavModule} from "@angular/material/sidenav";
import {MatSnackBarModule} from "@angular/material/snack-bar";
import {MatToolbarModule} from "@angular/material/toolbar";
import {RouterModule} from "@angular/router";
import {TranslateModule} from "@ngx-translate/core";
import {MatTooltipModule} from "@angular/material/tooltip";
import {MainComponent} from "./main.component";
// import {FlexLayoutModule} from "@angular/flex-layout";
import {MainService} from "./main.service";
import {SharedModule} from "../../../shared.module";
import {ChangePasswordDialogComponent} from "../../account/user/change-password-dialog/change-password-dialog.component";
import {ScrollingModule} from "@angular/cdk/scrolling";
import {UserImageDialogComponent} from "../../account/user/user-image/user-image-dialog.component";
import {DetailLevelDialogComponent} from "../../levelup/detail-level-dialog/detail-level-dialog.component";
import {ChangeMyDataDialogComponent} from "../../account/user/change-my-data-dialog/change-my-data-dialog.component";
import {MatAutocompleteModule} from "@angular/material/autocomplete";
import {ModuleDialogComponent} from "../module-dialog/module-dialog.component";
import {LayoutDirective} from "../../../utilities/fpf-layout/layout.directive";
import {FlexDirective} from "../../../utilities/fpf-layout/flex.directive";
import {FlexLayoutDirective} from "../../../utilities/fpf-layout/flex-layout.directive";
import {
    FlexWrapResponsiveDirective
} from "../../../utilities/fpf-layout/fpf-layout-responsive/flex-wrap-responsive.directive";
import {
    FlexLayoutAlignResponsiveDirective
} from "../../../utilities/fpf-layout/fpf-layout-responsive/flex-layout-align-responsive.directive";
import {
    FlexLayoutGapResponsiveDirective
} from "../../../utilities/fpf-layout/fpf-layout-responsive/flex-layout-gap-responsive.directive";
import {
    FlexLayoutResponsiveDirective
} from "../../../utilities/fpf-layout/fpf-layout-responsive/flex-layout-responsive.directive";
import {
    SearchEmployeeBySkillDialogComponent
} from "./search-employee-by-skill-dialog/search-employee-by-skill-dialog.component";


@NgModule({
    imports: [
        CommonModule,
        ScrollingModule,
        SharedModule,
        // FlexLayoutModule,
        TranslateModule,
        RouterModule,
        MatButtonModule,
        MatIconModule,
        MatListModule,
        MatRippleModule,
        MatMenuModule,
        MatCardModule,
        MatTooltipModule,
        MatToolbarModule,
        MatSidenavModule,
        MatSnackBarModule,
        MatBadgeModule,
        MatAutocompleteModule,
        // NgOptimizedImageModule,
        LayoutDirective,
        FlexDirective,
        FlexLayoutDirective,
        FlexWrapResponsiveDirective,
        FlexLayoutAlignResponsiveDirective,
        FlexLayoutGapResponsiveDirective,
        FlexLayoutResponsiveDirective,
        MatLineModule
    ],
    declarations: [
        MainComponent,
        MenuComponent,
        ChangePasswordDialogComponent,
        UserImageDialogComponent,
        DetailLevelDialogComponent,
        ChangeMyDataDialogComponent,
        ModuleDialogComponent,
        SearchEmployeeBySkillDialogComponent
    ],
    providers: [
        MainService,
    ],
    schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class MainModule {
}
