<div [fpfFill]="true" class="login" fpfLayout="column" fpfLayoutAlign="center center">

    <mat-card class="card-login">

        <div id="main" mat-card-image fpfLayout="column" fpfLayoutAlign="center center" fpfLayoutGap="2">

            <div fpfFlex fpfLayout="column" fpfLayoutGap="2">
                <img alt="BIT" src="../../../../assets/images/bit-initial.svg" height="85px">
                <img alt="FPF-Tech" src="assets/images/logo-fpf.svg" height="30px" class="logo-fpf">
                <div fpfLayout="column" fpfLayoutAlign="center center" fpfLayoutGap="2">
                    <mat-button-toggle-group [value]="translate.currentLang" (change)="changeLanguage($event)">
                        <mat-button-toggle [value]="'pt'" aria-label="Text align center" class="w-full">
                            <img alt="brazil logo" src="assets/images/brazil.png">
                        </mat-button-toggle>
                        <mat-button-toggle [value]="'en'" aria-label="Text align left" fpfFlex class="w-full">
                            <img alt="united state of america logo" src="assets\images\usa.png">
                        </mat-button-toggle>
                    </mat-button-toggle-group>
                </div>

            </div>

            <div fpfFlex>
                <mat-tab-group>
                    <mat-tab label="Sou colaborador">
                        <div fpfLayout="column"
                             fpfLayoutAlign="center center"
                             fpfLayoutGap="2"
                             class="mt-3 mb-3">
                            <button (click)="loginAzure()"
                                    mat-raised-button
                                    color="success">{{ 'sign-in' | translate }}
                            </button>
                        </div>
                    </mat-tab>

                    <mat-tab label="Não sou colaborador">
                        <div fpfLayout="column" fpfLayoutAlign="center center" class="mt-5">

                            <form [formGroup]="formGroup">

                                <div fpfFlex fpfLayout="column">
                                    <mat-form-field>
                                        <mat-label>{{ 'user' | translate }}</mat-label>
                                        <input matInput formControlName="username">
                                        <mat-error validator></mat-error>
                                    </mat-form-field>
                                </div>
                                <div fpfFlex fpfLayout="column">
                                    <mat-form-field>
                                        <mat-label>{{ 'password' | translate }}</mat-label>
                                        <input type="password" matInput formControlName="password">
                                        <mat-error validator></mat-error>
                                    </mat-form-field>
                                </div>

                                <div fpfFlex fpfLayout="row" fpfLayoutAlign="center center" class="mb-3">
                                    <button mat-raised-button color="success"
                                            (click)="login()">{{ 'sign-in' | translate }}
                                    </button>
                                </div>

                                <div fpfFlex fpfLayout="row" fpfLayoutAlign="center center" fpfLayoutGap="2">
                                    <a routerLink="../create_user_candidate/">{{ 'want-register' | translate }}</a>
                                    <a routerLink="../reset_password/">{{ 'forgot-password' | translate }}</a>
                                </div>

                            </form>
                        </div>
                    </mat-tab>
                </mat-tab-group>
            </div>
        </div>
    </mat-card>

</div>
