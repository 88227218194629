<mat-toolbar class="mat-dialog-header">
    <span class="w-full">{{ "search" | translate }}</span>
    <button mat-icon-button [mat-dialog-close]="false">
        <mat-icon>close</mat-icon>
    </button>
</mat-toolbar>
<form [formGroup]="formGroup">
    <div fpfLayout="row" fpfLayoutGap="2" fpfLayoutAlign="stretch start" class="m-3">
        <mat-form-field fpfLayout fpfFlex>
            <mat-label>{{ 'skill' | translate }}</mat-label>

            <input [appAutocomplete]="skill" formControlName="skill" matInput>
            <app-autocomplete #skill
                              (changeEvent)="getSkills($event)"
                              [display]="'name'"
                              [id]="'id'"
                              [paginatedResult]="skills">
            </app-autocomplete>
            <app-autocomplete-control [for]="skill" [limit]="10" matSuffix></app-autocomplete-control>
        </mat-form-field>
        <button type="button" mat-mini-fab color="accent"
                matTooltip="{{'search' | translate}}"
                (click)="search()">
            <mat-icon>search</mat-icon>
        </button>
    </div>
</form>
<mat-card class="mat-table ml-3 mr-3 mb-3">
    <table mat-table [dataSource]="searchList">
        <ng-container matColumnDef="name">
            <th mat-header-cell *matHeaderCellDef> {{ 'name' | translate }}</th>
            <td mat-cell *matCellDef="let element"
                [attr.data-header]="'name' | translate">
                <app-image-user [avatar]="element?.avatar_minio" [name]="element?.name"
                                class="m-2"></app-image-user>
                {{ element.name }}
            </td>
        </ng-container>

        <ng-container matColumnDef="mapping">
            <th mat-header-cell *matHeaderCellDef> {{ 'mapping' | translate }}</th>
            <td mat-cell *matCellDef="let element"
                [attr.data-header]="'mapping' | translate">
                @if (element.exists_on_mapping) {
                    <mat-icon class="ml-5"
                              color="accent"
                              matTooltip="{{ 'is-check' | translate }}">
                        check_circle
                    </mat-icon>
                } @else {
                    <mat-icon class="ml-5"
                              color="warn"
                              matTooltip="{{ 'not-check' | translate }}">
                        remove_circle_outline
                    </mat-icon>
                }
            </td>
        </ng-container>

        <ng-container matColumnDef="curriculum">
            <th mat-header-cell *matHeaderCellDef> {{ 'curriculum' | translate }}</th>
            <td mat-cell *matCellDef="let element"
                [attr.data-header]="'curriculum' | translate">
                @if (element.exists_on_curriculum) {
                    <mat-icon class="ml-3"
                              color="accent"
                              matTooltip="{{ 'is-check' | translate }}">
                        check_circle
                    </mat-icon>
                } @else {
                    <mat-icon class="ml-3"
                              color="warn"
                              matTooltip="{{ 'not-check' | translate }}">
                        remove_circle_outline
                    </mat-icon>
                }
            </td>
        </ng-container>

        <ng-container matColumnDef="course">
            <th mat-header-cell *matHeaderCellDef> {{ 'course' | translate }}</th>
            <td mat-cell *matCellDef="let element"
                [attr.data-header]="'course' | translate">
                @if (element.exists_on_course) {
                    <mat-icon class="ml-1"
                              color="accent"
                              matTooltip="{{ 'is-check' | translate }}">
                        check_circle
                    </mat-icon>
                } @else {
                    <mat-icon class="ml-1"
                              color="warn"
                              matTooltip="{{ 'not-check' | translate }}">
                        remove_circle_outline
                    </mat-icon>
                }
            </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
    </table>

    @if (paginator.length === 0) {
        <div class="no-records-row">
            {{ 'no-records-found' | translate }}
        </div>
    }

    <div class="paginator">
        <mat-paginator #paginator
                       [showFirstLastButtons]="true"
                       [length]="paginator.length"
                       [pageIndex]="paginator.pageIndex"
                       [pageSize]="paginator.pageSize"
                       [pageSizeOptions]="paginator.pageSizeOptions"
                       (page)="search()">
        </mat-paginator>
    </div>
</mat-card>
