import {Component, EventEmitter, Input, OnInit} from "@angular/core";
import {Utils} from "../../utilities/utils";

@Component({
    selector: "app-image-user",
    templateUrl: "./image-user.component.html",
    styleUrls: ["./image-user.component.scss"]
})
export class ImageUserComponent implements OnInit {

    @Input() avatar = null;
    @Input() name = "";
    @Input() changeEvent = new EventEmitter();
    @Input() isFeedbackActive: boolean = false;
    @Input() isProfile: boolean = false;
    @Input() isToSelection: boolean = false;

    public nickname = "";

    constructor() {
    }

    ngOnInit() {
        if (this.name) {
            this.nickname = Utils.getNickname(this.name);
        }
    }

}
