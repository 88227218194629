import {Component, Injector, OnInit} from "@angular/core";
import {BaseComponentDirective, BaseOptions} from "../../base-component.directive";
import {MainService} from "../../default/main/main.service";
import {URLS} from "../../../app/app.urls";
import {MappingLevelDetail} from "../../../models/levelup/mapping-level-detail";

const BASE_OPTIONS: BaseOptions = {
    endpoint: URLS.MAPPING_LEVEL_DETAIL,
    searchOnInit: true
};

@Component({
    selector: "app-detail-level-dialog",
    templateUrl: "./detail-level-dialog.component.html",
    styleUrls: ["./detail-level-dialog.component.scss"]
})
export class DetailLevelDialogComponent extends BaseComponentDirective<MappingLevelDetail> implements OnInit {

    constructor(public mainService: MainService,
                public injector: Injector) {
        super(injector, BASE_OPTIONS);
    }

    createFormGroup(): void {
    }

    public createArray(counter: number): number[] {
        return Array.from(new Array(counter), (val, index) => index + 1);
    }

}
