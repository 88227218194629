import {Component, Injector, OnInit, ViewChild} from "@angular/core";
import {BaseComponentDirective, BaseOptions} from "../../../base-component.directive";
import {MainService} from "../main.service";
import {URLS} from "../../../../app/app.urls";
import {takeUntil} from "rxjs/operators";
import {PaginatedResult} from "../../../../dto/paginated-result";
import {Skill} from "../../../../models/levelup/skill";
import {User} from "../../../../models/account/user";
import {AutocompleteEvent} from "../../../../shared/autocomplete/autocomplete-control.component";
import {MatPaginator} from "@angular/material/paginator";

const BASE_OPTIONS: BaseOptions = {
    pk: "id",
    searchOnInit: true,
    endpoint: URLS.SKILL,
};

interface Search {
    exists_on_course: boolean;
    exists_on_curriculum: boolean;
    exists_on_mapping: boolean;
    id: number;
    name: string;
    url: string;
    avatar_minio: string
}

@Component({
    selector: "app-search-employee-by-skill-dialog",
    templateUrl: "./search-employee-by-skill-dialog.component.html",
    styleUrl: "./search-employee-by-skill-dialog.component.scss"
})
export class SearchEmployeeBySkillDialogComponent extends BaseComponentDirective<Skill> implements OnInit {

    @ViewChild(MatPaginator, {static: true}) paginator: MatPaginator;

    public displayedColumns = ["name", "mapping", "curriculum", "course"];
    public skills: PaginatedResult<Skill> = new PaginatedResult();
    public show: boolean = false;
    public searchList: Search[] = [];
    public user: User;

    constructor(public mainService: MainService,
                public injector: Injector,
    ) {
        super(injector, BASE_OPTIONS);
    }


    public createFormGroup(): void {
        this.formGroup = this.formBuilder.group({
            skill: [null],
        });
    }

    public search(): void {
        if (this.v.skill) {
            this.getDetailsUsers();
        }
    }

    private getDetailsUsers(): void {
        this.service.clearParameter();
        this.service.addParameter("limit", this.paginator.pageSize);
        this.service.addParameter("offset", (this.paginator.pageIndex * this.paginator.pageSize));
        this.service.addParameter("skill", this.v.skill);
        this.service.getFromListRoute("details_skill")
            .pipe(takeUntil(this.unsubscribe))
            .subscribe((response) => {
                this.searchList = response["employee"];
                this.paginator.length = response["paginator_count"];
            });
    }


    public getSkills(event: AutocompleteEvent): void {
        this.service.clearParameter();
        this.service.addParameter("limit", event.limit);
        this.service.addParameter("offset", event.offset);
        this.service.addParameter("name", event.searchText);
        if (this.v.skill) {
            this.service.addParameter("skill_area_or_skill", this.v.skill);
        }
        this.service.getPaginated()
            .pipe(takeUntil(this.unsubscribe))
            .subscribe(response => this.skills = response);
    }
}
