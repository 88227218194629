import {ChangeDetectorRef, Component, EventEmitter, OnDestroy, OnInit} from "@angular/core";
import {AuthService} from "../../../services/auth.service";
import {TranslateService} from "../../../services/translate.service";
import {MainService} from "./main.service";
import {SIDENAV_CONTENT_EXPANDED, SIDENAV_EXPANDED} from "./menu/menu.animation";
import {MenuItem} from "./menu/menu.component";
import {distinctUntilChanged, map, takeUntil} from "rxjs/operators";
import {MatDialog} from "@angular/material/dialog";
import {CUSTOM_DATE_ADAPTER} from "../../../app/app.constant";
import {User} from "../../../models/account/user";
import {AppVariables} from "../../../app/app.variables";
import {Subject} from "rxjs";
import {Router} from "@angular/router";
import {BaseService} from "../../../services/base.service";
import {URLS} from "../../../app/app.urls";
import {HttpClient} from "@angular/common/http";
import {Location} from "@angular/common";
import {DetailLevelDialogComponent} from "../../levelup/detail-level-dialog/detail-level-dialog.component";
import {OverlayContainer} from "@angular/cdk/overlay";
import {Module, ModuleType} from "../../../models/account/module";
import {ModuleDialogComponent} from "../module-dialog/module-dialog.component";
import {
    SearchEmployeeBySkillDialogComponent
} from "./search-employee-by-skill-dialog/search-employee-by-skill-dialog.component";
import {ModuleMenu} from "../../../models/account/module-menu";
import {VersionService} from "../../../services/version.service";
import {MatSnackBar} from "@angular/material/snack-bar";


@Component({
    selector: "app-main",
    templateUrl: "./main.component.html",
    styleUrls: ["./main.component.scss"],
    animations: [SIDENAV_EXPANDED, SIDENAV_CONTENT_EXPANDED],
    providers: [CUSTOM_DATE_ADAPTER]
})
export class MainComponent implements OnInit, OnDestroy {
    private unsubscribe = new Subject();
    public changeEvent = new EventEmitter();

    public menuItems: MenuItem[] = [];

    public isExpanded = true;
    public user: User;

    public selectedModule: Module;
    public modules: Module[] = [];
    public moduleService: BaseService<Module>;

    public moduleMenuService: BaseService<ModuleMenu>;

    protected readonly ModuleType = ModuleType;

    public versionWEB: string;
    public versionAPI: string;

    public title: string;

    public showSelectSkillMapping: boolean = false;

    constructor(public variables: AppVariables,
                public authService: AuthService,
                public mainService: MainService,
                public translate: TranslateService,
                public dialog: MatDialog,
                public http: HttpClient,
                public location: Location,
                public router: Router,
                public overlayContainer: OverlayContainer,
                public versionService: VersionService,
                public snackBar: MatSnackBar,
                private cd: ChangeDetectorRef) {

        this.moduleService = new BaseService<Module>(this.http, URLS.MODULE);
        this.moduleMenuService = new BaseService<ModuleMenu>(this.http, URLS.MODULE_MENU);
    }


    public ngOnInit() {
        this.onChangeTitle();
        this.onChangeSnackBar();
        this.onChangeAvatar();
        this.onChangeListenerShowSelectSkillMapping();

        this.isExpanded = window.screen.width > 1024;
        this.user = this.authService.user;
        this.checkLogout();

        this.getModules();
        this.getVersions();
    }

    public getVersions() {
        this.versionService.get("VERSION_WEB").pipe(
            takeUntil(this.unsubscribe),
            map(items => {
                if (items && items.length > 0) {
                    return items[0];
                }
            })
        ).subscribe(settings => {
            this.versionWEB = settings?.value ?? "1.0.0";
        });

        this.versionService.get("VERSION_API").pipe(
            takeUntil(this.unsubscribe),
            map(items => {
                if (items && items.length > 0) {
                    return items[0];
                }
            })
        ).subscribe(settings => {
            this.versionAPI = settings?.value ?? "1.0.0";
        });

    }

    public setDefaultModule() {
        // caso tenha um módulo no localStorage ai utiliza esse modulo, senão vai pegar um padrão de acordo com o tipo do usuário
        const module = JSON.parse(localStorage.getItem("selectedModule"));
        if (module) {
            this.selectedModule = module;
        } else {
            if (this.user.is_candidate) {
                this.selectedModule = this.modules.find(x => x.description == this.ModuleType.RH);
            } else {
                this.selectedModule = this.modules.find(x => x.description == this.ModuleType.LEVELUP);
            }
        }
        if (this.selectedModule) {
            localStorage.setItem("selectedModule", JSON.stringify(this.selectedModule));

            this.overlayContainer.getContainerElement().classList.add(this.selectedModule.theme);
            this.getMenus();
        } else {
            this.logout();

        }
    }

    public checkLogout(): void {
        if (!this.authService.isLoggedIn()) {
            this.logout();
            return;
        }
    }

    //
    // public getUser(): void {
    //     this.userService.getById(this.authService.user.id)
    //         .subscribe(response => {
    //             this.user = response;
    //         });
    // }


    // // title page
    public onChangeTitle(): void {
        this.mainService.changeTitle.pipe(
            takeUntil(this.unsubscribe),
            distinctUntilChanged()
        ).subscribe(nextTitle => {
            this.title = nextTitle;
            this.cd.detectChanges();
        });
    }


    // snack bar
    public onChangeSnackBar(): void {
        this.mainService.changeSnackBar.pipe(
            takeUntil(this.unsubscribe),
            distinctUntilChanged()
        ).subscribe(message => this.showSnackBar(message));
    }

    public showSnackBar(message: string) {
        this.snackBar.open(message, null, {
            duration: 5000,
            panelClass: "snack-bar"
        });
    }


    // resize screen
    public onResize(event): void {
        const windowWidth = event.target.innerWidth;
        this.isExpanded = windowWidth > 1024;
    }

    public openProfile(): void {
        this.router.navigate(["/account/profile"]).then();
    }

    public getModules(): void {
        this.moduleService.clearParameter();
        this.moduleService.addParameter("user", this.authService.user.id);
        this.moduleService.addParameter("granted", true);
        this.moduleService.addParameter("is_active", true);
        this.moduleService.getAll("with_granted")
            .pipe(takeUntil(this.unsubscribe))
            .subscribe(response => {
                this.modules = response;
                this.setDefaultModule();
            });
    }

    public getMenus(): void {
        this.menuItems = [];
        this.moduleMenuService.clearParameter();
        this.moduleMenuService.addParameter("user", this.user.id);
        this.moduleMenuService.addParameter("module", this.selectedModule?.description);
        this.moduleMenuService.getAll("find_menu")
            .pipe(takeUntil(this.unsubscribe))
            .subscribe(response => {
                Object.assign(this.menuItems, response["results"]);
                // Adiciona o menu no localStorage para que seja possível validar no AppGuard
                localStorage.setItem("menus", JSON.stringify(this.menuItems));
            });
    }

    public logout(): void {
        this.authService.logout(true, true);
        localStorage.clear();
    }


    public openCurriculum(): void {
        this.router.navigate(["account/curriculum/logged"]).then();
    }

    public onChangeAvatar(): void {
        this.mainService.changeAvatar
            .pipe(takeUntil(this.unsubscribe))
            .subscribe((avatar) => {
                this.user.avatar_minio = avatar;
                this.changeEvent.emit(this.user.avatar_minio);
            });
    }


    public openDetailLevelDialog(): void {
        this.dialog.open(
            DetailLevelDialogComponent, {
                width: "600px"
            }
        );
    }

    public openModuleDialog(): void {
        this.dialog.open(ModuleDialogComponent, {
            minWidth: "100%",
            height: "100%",
            data: {
                user: this.user,
                selectedModule: this.selectedModule,
                modules: this.modules
            }
        });
    }


    public openSearchDialog(): void {
        const config = {
            width: "50rem",
            maxHeight: "50rem"
        };
        this.dialog.open(SearchEmployeeBySkillDialogComponent, config);
    }

    public openSkill(): void {
        this.mainService.openSkill.next();
    }

    public onChangeListenerShowSelectSkillMapping() {
        this.mainService.showSelectSkillMapping.pipe(takeUntil(this.unsubscribe)).subscribe(response => {
            this.showSelectSkillMapping = response;
        });
    }


    public ngOnDestroy() {
        this.unsubscribe.complete();
    }

}
